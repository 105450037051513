import { GridColumn } from '@progress/kendo-react-grid'
import React, { useState } from 'react'
import { ListScreen } from '../../components';
import { useParams } from 'react-router-dom';

const SettingsChild = () => {

    const { code } = useParams()
    const [usersenddata] = useState({ parent_code: code });

    return (
        <div>
            <ListScreen
                read={'co000002'}
                delete={'co000004'}
                userSendData={usersenddata}
                showDetail={false}
            >
                <GridColumn field='name' />
                <GridColumn field='code' />
            </ListScreen>
        </div>
    )
}

export default SettingsChild