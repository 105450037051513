import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ChangeLanguage, FibaDropdown, FibaInput, Form, send, SetCookie, useFibaMsgBox, useLoading } from "../../components";
import { Field } from "@progress/kendo-react-form";
import { FibaPassInput } from "../../components/input/input-text/FibaPassInput";
import { encrtypth } from "../../service/CoreFunctions";
import { TOKEN_NAME } from "../../constants/token.const";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

export default function Registration() {
    const { t } = useTranslation();
    const { setAuth } = useContext(AuthContext)
    const { error, success } = useFibaMsgBox();
    const { showLoading } = useLoading()
    const navigate = useNavigate()

    const register = async (formdata) => {
        const data = {
            ...formdata,
            password: encrtypth(formdata.password),
            password_confirmation: encrtypth(formdata.password_confirmation),
        }
        const res = await send("lo010102", data, showLoading, true);
        if (res.status === 'error') {
            error(res.message)
        } else {
            sessionStorage.clear()
            localStorage.clear()
            SetCookie(TOKEN_NAME, res.token);
            setAuth(res);
            success(t('success'));
            navigate('/');
        }
    }
    
    return (
        <>
            <div className="absolute top-1 right-2">
                <ChangeLanguage />
            </div>
            <div className="flex min-h-full flex-1">
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        alt=""
                        src="/background-image/login-bg.jpg"
                        className="absolute inset-0 h-full w-full object-cover"
                    />
                </div>
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div className="bg-khanaltai_pattern object-cover bg-cover p-2">
                            <img
                                alt="Your Company"
                                src="/mecore-logos/main-logo/logo_4.png"
                                className="h-10 w-auto"
                            />
                        </div>
                        <h2 className="mt-3 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            {t('register')}
                        </h2>
                        <div className="mt-3">

                            <Form onSubmit={register} showAction={false}>
                                <div className="space-y-2">
                                    <Field
                                        name={'company'}
                                        component={FibaDropdown}
                                        label={t('company')}
                                        required={true}
                                        placeholder={t('company')}
                                        dictCode={'DIC_009'}
                                    />
                                    <Field
                                        name={'lastname'}
                                        component={FibaInput}
                                        label={t('lastname')}
                                        required={true}
                                        placeholder={t('lastname')}
                                    />
                                    <Field
                                        name={'name'}
                                        component={FibaInput}
                                        label={t('name')}
                                        required={true}
                                        placeholder={t('name')}
                                    />
                                    <Field
                                        name={'position'}
                                        component={FibaInput}
                                        label={t('position')}
                                        required={true}
                                        placeholder={t('position')}
                                    />
                                    <Field
                                        name={'division'}
                                        component={FibaDropdown}
                                        label={t('division')}
                                        dictCode={'DIC_007'}
                                        required={true}
                                    />
                                    <Field
                                        name={'phone'}
                                        component={FibaInput}
                                        label={t('phone')}
                                    />
                                    <Field
                                        name={'email'} component={FibaInput}
                                        label={t('email')} required={true}
                                        placeholder={t('email')}
                                        autoComplete={'email'}
                                    />

                                    <Field
                                        name={'password'}
                                        component={FibaPassInput}
                                        placeholder="••••••••"
                                        autoComplete={'current-password'}
                                        label={t('password')}
                                        type="password" required
                                    />

                                    <Field
                                        name={'password_confirmation'}
                                        component={FibaPassInput}
                                        type="password"
                                        label={t('password_confirmation')}
                                        required={true}
                                        placeholder={t('password_confirmation')}
                                        autoComplete={'new-password'}
                                    />

                                    <div>
                                        <button
                                            type="submit"
                                            className="flex w-full justify-center rounded-md border border-transparent bg-secondary-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
                                        >
                                            {t('register')}
                                        </button>

                                        <Link
                                            to="/login"
                                            className="my-2 flex w-full justify-center rounded-md border border-transparent border-secondary-500 py-2 px-4 text-sm font-medium text-secondary-500 shadow-sm hover:bg-secondary-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
                                        >
                                            {t('login')}
                                        </Link>
                                    </div>

                                </div>
                            </Form>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
