/* eslint-disable react-hooks/exhaustive-deps */
import { send, useFibaMsgBox, useLoading } from '../components';
import { useState } from "react";
import { useEffect } from "react"
import { ADMIN_ROLES, INFORMATIONSTATUS } from "../constants/data.cont";
import { useTranslation } from "react-i18next";
import FibaButton from '../components/button/FibaButton/FibaButton';
import UserDetailEdit from '../pages/user/UserDetailEdit';

export default function Profile() {
  const { showLoading } = useLoading()
  const [info, setInfo] = useState();
  const [stats, setstats] = useState([]);
  const { error } = useFibaMsgBox();
  const { t } = useTranslation()
  const [dataDetail, setDataDetail] = useState({
    showDetail: false, data: {}
  });

  const openEdit = () => {
    setDataDetail({showDetail: true, data: info.user});
  }

  useEffect(() => {
    getDetail();
    getInformationStatusDetail()
  }, [])

  const getDetail = async () => {
    const res = await send("lo010100", {}, showLoading);
    if (res.status === 'error') {
      error(res.message)
    } else {
      for (let index = 0; index < ADMIN_ROLES.length; index++) {
        const element = ADMIN_ROLES[index];
        if (element.value === res?.user?.role) {
          res.user.rolename = t(element.name);
          break;
        }
      }
      setInfo(res);
    }
  }

  const getInformationStatusDetail = async () => {
    const res = await send("lo020106", {}, showLoading);
    if (res.status === 'error') {
      error(res.message)
    } else {
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        for (let index2 = 0; index2 < INFORMATIONSTATUS.length; index2++) {
          const element2 = INFORMATIONSTATUS[index2];
          if (`${element.name}` === `${element2.value}`) {
            res[index].name = element2.name;
          }
        }

      }
      setstats(res);
    }
  }

  return (
    <>
      <main className="py-4">

        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                    {t('generalInfo')}
                  </h2>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className='flex justify-end'>
                    <FibaButton buttonType={'icon'} onClick={openEdit}>
                      <i className='fiba-i-edit' />
                    </FibaButton>
                  </div>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('id')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.id}</dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('name')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.name}</dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('email')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.email}</dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('position')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.position}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('division')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.divisionname}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('role')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.rolename}</dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('created_at')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.created_at}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('phone')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.phone}</dd>
                    </div>

                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mt-8 mx-auto max-w-2xl lg:max-w-none">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              {t('addinformation')}
            </h2>
            <hr />
            <dl className=" grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat, index) => (
                <div key={index} className="flex flex-col bg-gray-400/5 p-8">
                  <dt className="text-sm font-semibold leading-6 text-gray-600">{t(stat.name)}</dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </main>
      {dataDetail.showDetail && (
        <UserDetailEdit
          closeModal={() => {
            setDataDetail({ showDetail: false });
            getDetail();
          }}
          modalState={dataDetail.showDetail}
          data={dataDetail.data}
        />
      )}
    </>
  )
}
