import React, { useEffect, useState, useImperativeHandle } from "react";
import {
    LocalizationProvider,
    loadMessages
} from "@progress/kendo-react-intl";
import enMessages from "./lang/en.json"
import mnMessages from "./lang/mn.json"
import './style.css'
import { useTranslation } from 'react-i18next'
import { getSelectedState, Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { useFibaMsgBox, useLoading } from "../features";
import { process } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import functions from "./functions"
import { pagerSettings } from "./constant";
import { send } from "../service/api-service/services";
import DetailCell from "./cell/DetailCell/DetailCell";
// import { ColumnMenu, ColumnMenuCheckboxFilter } from "./column";
import { CommandCell } from "./cell/action/CommandCell";
import FibaButton from "../button/FibaButton/FibaButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftCircleIcon, ArrowPathIcon, PlusIcon, ArrowDownTrayIcon, DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import CheckBoxCell from "./cell/checkBoxCell/CheckBoxCell";
import { FibaFilter } from "./filter";
import { ConstDropDownCell } from "./cell/DropdownCell/ConstDropDownCell";
import FloatCell from "./cell/FloatCell/FloatCell";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";

const createDataState = (dataState) => {
    return {
        result: process([], dataState),
        dataState: dataState,
    };
};
const initPerPage = 20;

/**
 * Жагсаалт дэлгэц
 * @param  {} createable Үүсгэх товч харуулах эсэх
 * @param  {} readable Жагсаалт хүсэлт илгээх эсэх
 * @param  {} downloadable Татах товч харуулах эсэх
 * @param  {} navigationUrl Үүсгэх дэлгэц дээр нэмэлт url оруулах
 * @param  {} customDetailUrl Дэлгэрэнгүй дэлгэц дуудагдах custom url
 * @param  {} userSendData
 * @param  {} foreignKey={} Гадны түлхүүртэйгээр устгах үйлдэлд ашиглана
 * @param  {} showBack=false
 * @param  {} swapfield={}
 * @param  {} userFilters=[]
 * @param  {} defaultOrders=[]
 * @param  {} refreshable=true
 * @param  {} pageable=true
 * @param  {} filterable=false
 * @param  {} showToolbar=true
 * @param  {} selectable=false
 * @param  {} selectedField='selected'
 * @param  {} dataItemKey='id'
 * @param  {} setSelectedValue
 * @param  {} editable
 * @param  {} data
 * @param  {} editField="inEdit"
 * @param  {} save_pc
 * @param  {} customFunction
 * @param  {} customFunctionText
 * @param  {} showDetail=true
 * @param  {} detailFuncion
 * @param  {} endCustomComponent custome component refresh button урд гаргах
 * @param  {} gridToolbarCustom custom component filter урд гаргах
 * @param  {} pdfExportable=false PDF хөрвүүлэх товч харуулах эсэх
 * @param  {} pdfExportScale=0.4 хөрвүүлэх pdf-ийн scale
 * @param  {} pdfExportFileName='pdf export' хөрвүүлэх pdf-ийн file name
 * @param  {} masterDetailComponent master detail-ийн component
 * @param  {} ...props
 */
export const ListScreen = React.forwardRef(({
    createable = true,
    readable = true,
    downloadable = false,
    navigationUrl,
    customDetailUrl,
    userSendData,
    showBack = false,
    swapfield = {},
    userFilters = [],
    defaultOrders = [],
    refreshable = true,
    pageable = true,
    filterable = true,
    showToolbar = true,
    selectable = false,
    selectedField = 'selected',
    dataItemKey = 'id',
    setSelectedValue,
    selectedValue = {},
    editable,
    data,
    editField = "inEdit",
    save_pc,
    customFunction,
    customFunctionText,
    showDetail = true,
    detailFuncion,
    foreignKey = {},
    deleteTitle,
    createTitle = 'create',
    downloadTitle = 'download',
    setData,
    selectablemode = 'multiple',
    height = "100%",
    endCustomComponent = null,
    gridToolbarCustom = null,
    optional,
    optionalTitle,
    optionalIcon,
    selectedFieldName = 'name',
    onExpandChange,
    masterDetailComponent,
    onRowDoubleClick,
    expandField,
    pdfExportable = false,
    pdfExportScale = 0.4,
    pdfExportFileName = 'pdf export',
    excelExportCustom,
    ...props
}, ref) => {
    const { i18n, t } = useTranslation();
    const { showLoading } = useLoading()
    const navigate = useNavigate()
    const { confirm, error, success } = useFibaMsgBox()
    const [griddata, setGridData] = useState({ data: data ?? [] });
    const [tmpdata, setTmpData] = useState({ data: data ?? [] });
    const location = useLocation()
    const gridInfo = (() => {
        const gridInfoStr = localStorage.getItem('gridinfo');

        const initObj = {
            dataState: { take: initPerPage, skip: 0 },
            requestData: {
                order: [],
                perPage: initPerPage,
                page: 1,
                filters: userFilters
            }
        };

        if (!gridInfoStr) return initObj;

        const gridInfoParsed = JSON.parse(gridInfoStr)[props.read];

        return gridInfoParsed ? gridInfoParsed : initObj
    })();

    let initialState = createDataState(gridInfo.dataState);
    const [dataState, setDataState] = useState(initialState.dataState);

    const [requestData, setRequestData] = useState(gridInfo.requestData)
    const [sendData, setSendData] = useState({})

    const newChildren = [];
    let deleteable = true;
    let pc_delete = '';
    const filterfields = [];
    const [selectedState, setSelectedState] = React.useState(selectedValue);
    const idGetter = getter(dataItemKey);
    const _export = React.useRef(null);
    const _grid = React.useRef();

    const excelExport = () => {
        if (excelExportCustom) {
            excelExportCustom()
        } else {
            if (_export.current !== null) {
                _export.current.save(griddata?.data, _grid.current.columns);
            }
        }
    };

    let gridPDFExport;
    const exportPDF = () => {
        setTimeout(() => {
            if (gridPDFExport) {
                gridPDFExport.save(griddata);
            }
        }, 250);
    };

    const onSelectionChange = React.useCallback(
        (event) => {

            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: dataItemKey,
            });
            setSelectedState(newSelectedState);
        },
        [dataItemKey, selectedState]
    );

    const onHeaderSelectionChange = React.useCallback((event) => {
        if (selectablemode === 'single') {
            return;
        }
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, [idGetter, selectablemode]);

    if (props.deleteable === false) {
        deleteable = props.deleteable;
    }

    // delete process code set хийж өгөх
    if (props.delete) {
        pc_delete = props.delete;
    }

    const MyCustomCellDetail = (props) => <DetailCell {...props}
        dataItemKey={dataItemKey}
        customDetailUrl={customDetailUrl}
        detailFuncion={detailFuncion}
    />

    // columns дээр custom хийхэд зориулсан
    if (selectable) {
        newChildren.push(
            <GridColumn
                key={props.children.length + 1}
                field={selectedField}
                width="50px"
                headerSelectionValue={
                    griddata?.data ?
                        (griddata?.data.findIndex((item) => !selectedState[idGetter(item)]) === -1)
                        : false
                }
            />
        );
    }
    if (props.children?.props) {
        props.children = [props.children];
    }
    props.children.forEach((child, index) => {
        const subChild = {
            key: index,
            dataItemKey
        }
        if (child.props) {
            const filter = child.props.filter;
            if (filter || child.props.filterType) {
                filterfields.push({
                    field: swapfield[child.props.field] ?? child.props.field,
                    title: child.props.title,
                    type: filter ?? child.props.filterType,
                    dictCode: child.props.dictCode,
                    constData: child.props.constData
                });
            }

            if (!child.props.hidden) {

                if (child.props.field === selectedFieldName && showDetail) {
                    subChild.cell = MyCustomCellDetail;
                    subChild.locked = true;
                } else {
                    if (child.props.constData) {
                        if (!child.props.cell) {
                            const constDataCell = (props) => {
                                return <ConstDropDownCell constdata={child.props.constData} {...props} />
                            }
                            subChild.cell = constDataCell
                        }
                    } else {
                        switch (child.props.fieldType) {
                            case 'boolean':
                                subChild.cell = CheckBoxCell
                                break;
                            case 'float':
                                subChild.cell = FloatCell
                                break;
                            default:
                                break;
                        }
                    }
                }
                newChildren.push(
                    React.cloneElement(child, subChild)
                );
            }
        }
    });

    const enterEdit = (dataItem) => {
        let newData = griddata.data.map((item) =>
            item[dataItemKey] === dataItem[dataItemKey]
                ? {
                    ...item,
                    inEdit: true,
                    startdate: item.startdate ? new Date(item.startdate) : null,
                    enddate: item.enddate ? new Date(item.enddate) : null,
                }
                : item
        );
        setGridData({ ...griddata, data: newData });
    };

    const cancel = (dataItem) => {
        if (dataItem[dataItemKey] === '0') {
            setGridData(tmpdata);
            return;
        }
        const originalItem = tmpdata.data.find(
            (p) => p[dataItemKey] === dataItem[dataItemKey]
        );
        const newData = griddata.data.map((item) =>
            item[dataItemKey] === originalItem[dataItemKey] ? originalItem : item
        );
        setGridData({ ...griddata, data: newData });
    };

    const saveEditRowData = async (dataItem) => {
        if (dataItem[dataItemKey]) {
            if (save_pc) {
                const isConfirmed = await confirm(t('confirmsave'));
                if (isConfirmed) {
                    const res = await send(save_pc, dataItem, showLoading);
                    if (res.status === 'error') {
                        error(res.message)
                    } else {
                        success(res);
                        readData();
                    }
                }
            } else {
                const currentDate = new Date();
                let newData = griddata.data.map((item) =>
                    item[dataItemKey] === dataItem[dataItemKey]
                        ? {
                            ...item,
                            [dataItemKey]: currentDate.getTime(),
                            inEdit: false
                        }
                        : item
                );
                setGridData({ ...griddata, data: newData });
            }
        }
    }

    if (deleteable || editable) {
        const commandCell = (propsc) => <CommandCell
            {...propsc}
            remove={deleteable ? remove : null}
            edit={editable ? enterEdit : null}
            cancel={cancel}
            editField={editField}
            save={saveEditRowData}
            deleteTitle={deleteTitle}
            dataCount={griddata.data.length}
            optional={optional}
            optionalTitle={optionalTitle}
            optionalIcon={optionalIcon}
        />;
        newChildren.push(
            <GridColumn key={props.children.length + 1} field="" cell={commandCell} width={100} />
        );
    }

    const addNew = () => {
        if (editable) {
            let maxamount = 0;
            for (let index = 0; index < griddata.data.length; index++) {
                const element = griddata.data[index];
                if (element[dataItemKey] === '0') {
                    return;
                }

                if (element.maxamount > maxamount) {
                    maxamount = element.maxamount;
                }
            }
            const newDataItem = {
                [editField]: true,
                [dataItemKey]: '0',
                intervalno: griddata.data.length + 1,
                minamount: maxamount
            };
            setGridData({ ...griddata, data: [newDataItem, ...griddata.data] });
        } else {
            if (navigationUrl) {
                navigate(`${location.pathname}/${navigationUrl}/0`);
            } else {
                navigate(`${location.pathname}/0`);
            }
        }
    }

    const goBack = () => {
        navigate(-1)
    }

    const remove = async (dataItem) => {
        if (pc_delete) {
            const isConfirmed = await confirm(t('confirmdelete'));
            if (isConfirmed) {
                const snddata = { ...foreignKey };
                snddata[dataItemKey] = dataItem[dataItemKey];
                const res = await send(pc_delete, snddata, showLoading);
                if (res.status === 'error') {
                    error(res.message)
                } else {
                    success(t('success'))
                    readData();
                }
            } else {
                console.log("Declined.");
            }
        } else {
            if (editable) {
                const tmp = griddata.data.filter((obj) => obj[dataItemKey] !== dataItem[dataItemKey]);
                setGridData({ griddata, data: tmp });
            }
        }
    };

    if (i18n.language === 'en') {
        loadMessages(enMessages, "en-US");
    } else {
        loadMessages(mnMessages, "mn-MN");
    }

    const readData = async () => {
        if (!readable) {
            return;
        }
        if (defaultOrders.length > 0) {
            if (requestData?.orders?.length < 1) {
                for (let index = 0; index < defaultOrders.length; index++) {
                    const element = defaultOrders[index];
                    requestData.orders.push(element);
                }
            }
        }
        let sendData = requestData;
        if (userSendData) {
            let filters = [];
            if (userSendData.filters) {
                filters = [...requestData.filters, ...userSendData.filters];
            } else {
                filters = requestData.filters;
            }
            sendData = { ...requestData, ...userSendData, filters };
        }
        if (!props.read) {
            return;
        }
        if (localStorage.getItem('checkduplicate-' + props.read)) {
            return;
        } else {
            localStorage.setItem('checkduplicate-' + props.read, true);
        }
        setSendData(sendData)
        let res = await send(props.read, { ...sendData }, showLoading);
        localStorage.removeItem('checkduplicate-' + props.read);
        if (res.status === 'error') {
            error(res.message)
        } else {
            let tmp = res;
            if (res.data) {
                tmp = res.data;
            }
            if (selectable) {
                for (let index = 0; index < tmp.length; index++) {
                    const element = tmp[index];
                    element.selected = false
                }
            }
            setGridData({ data: tmp, total: res.total });
            setTmpData({ data: tmp, total: res.total });
        }
    }

    const dataStateChange = async (event) => {
        const reqData = await functions.getListRequest({ swap: swapfield, ...event.dataState });
        setRequestData({ ...requestData, ...reqData });
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
    };

    const choosedData = (event) => {
        if (props.chooseData) {
            props.chooseData(event.dataItem);
        }
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = griddata.data.map((item) =>
            item[dataItemKey] === event.dataItem[dataItemKey]
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        setGridData({ ...griddata, data: newData });
    };

    const expandChange = (event) => {
        let newData = griddata?.data.map((item) => {
            if (item[dataItemKey] === event.dataItem[dataItemKey]) {
                item.expanded = !event.dataItem.expanded;
            }

            return item;
        });
        setGridData({ ...griddata, data: newData })
    };

    const GridFilterUpdate = () => {
        return <FibaFilter
            process_code={props.read}
            requestData={requestData}
            setRequestData={setRequestData}
            filterfields={filterfields}
        />
    }

    // clickHandlerRef.current = readData;
    useImperativeHandle(ref, () => ({
        readData,
        GridFilterUpdate,
        setGridData,
        setTmpData,
        exportPDF,
        sendData
    }));

    useEffect(() => {
        if (setData) {
            setData(griddata)
        }
    }, [griddata, setData])

    useEffect(() => {
        if (setSelectedValue) {
            setSelectedValue(selectedState)
        }
    }, [selectedState, setSelectedValue])

    useEffect(() => {
        if (Object.keys(selectedValue).length) {
            setSelectedState(selectedValue)
        }
    }, [selectedValue])

    useEffect(() => {
        setGridData({ data: data ?? [] })
        setTmpData({ data: data ?? [] })
    }, [data])

    useEffect(() => {
        const obj = {
            'dataState': dataState,
            'requestData': requestData
        }
        const gridInfoStr = localStorage.getItem('gridinfo');

        if (!gridInfoStr) {
            localStorage.setItem(`gridinfo`, JSON.stringify({ [props.read]: obj }));
        } else {
            const gridInfoParsed = JSON.parse(gridInfoStr);
            gridInfoParsed[props.read] = obj;
            localStorage.setItem(`gridinfo`, JSON.stringify(gridInfoParsed));
        }

        readData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData]);

    useEffect(() => {
        setRequestData({ ...requestData, page: 1 })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSendData])

    useEffect(() => {
        const interval = setInterval(() => {
            if (localStorage.getItem('checkduplicate-' + props.read)) {
                localStorage.removeItem('checkduplicate-' + props.read);
            }
        }, 3000);
        return () => {
            clearInterval(interval)
        };
    }, [props.read])

    const grid = (
        <Grid
            ref={_grid}
            style={{ height, maxHeight: 'calc(100vh - 100px)' }}
            {...dataState}
            data={selectable ? griddata?.data.map((item) => ({
                ...item,
                [selectedField]: selectedState[idGetter(item)]
            })) : griddata?.data}
            total={griddata?.total}
            onDataStateChange={dataStateChange}
            pageable={pageable ? pagerSettings : pageable}
            sortable={{ mode: "multiple" }}
            resizable={true}
            onRowDoubleClick={choosedData}
            dataItemKey={dataItemKey}
            selectedField={selectedField}
            selectable={{
                enabled: false,
                drag: false,
                cell: false,
                mode: selectablemode,
            }}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            editField={editField}
            onItemChange={itemChange}
            onExpandChange={expandChange}
            detail={masterDetailComponent}
            expandField={expandField}
        >
            {showToolbar && <GridToolbar>
                <div className={'flex justify-between w-full flex-wrap space-y-3'}>
                    <div className="flex">
                        <div className="space-x-2">
                            {showBack &&
                                <FibaButton
                                    onClick={goBack}
                                    title={t('back')}
                                    buttonType={'icon'}
                                    icon={<ArrowLeftCircleIcon className="h-5 w-5" />}
                                >
                                </FibaButton>
                            }
                            {createable &&
                                <FibaButton
                                    onClick={addNew}
                                    title={t(createTitle)}
                                    icon={<PlusIcon className="-ml-1 mr-1 h-5 w-5" />}
                                >
                                    {t(createTitle)}
                                </FibaButton>
                            }
                            {downloadable &&
                                <FibaButton
                                    onClick={excelExport}
                                    title={t(downloadTitle)}
                                    icon={<ArrowDownTrayIcon className="-ml-1 mr-1 h-5 w-5" />}
                                >
                                    {t(downloadTitle)}
                                </FibaButton>
                            }
                            {
                                customFunction &&
                                <FibaButton
                                    onClick={customFunction}
                                    title={customFunctionText}
                                    icon={<PlusIcon className="-ml-1 mr-1 h-5 w-5" />}
                                >
                                    {customFunctionText}
                                </FibaButton>
                            }
                            {pdfExportable &&
                                <FibaButton
                                    onClick={() => exportPDF()}
                                    title={t('printpdf')}
                                    icon={<DocumentArrowDownIcon className="-ml-1 mr-1 h-5 w-5" />}
                                >
                                    {t('printpdf')}
                                </FibaButton>
                            }
                        </div>
                        {gridToolbarCustom}
                    </div>
                    <div>
                        {(filterfields.length > 0 && filterable) && <GridFilterUpdate />}
                    </div>
                    <div className="space-x-2">
                        {endCustomComponent}
                        {refreshable &&
                            <FibaButton
                                onClick={readData}
                                title={t('refresh')}
                                buttonType={'icon'}
                                themeColor={'none'}
                                icon={<ArrowPathIcon className="h-5 w-5" />}
                            >
                            </FibaButton>
                        }
                    </div>
                </div>
            </GridToolbar>}
            {newChildren}
        </Grid>
    )
    return (
        <>
            <LocalizationProvider language={i18n.language === 'en' ? 'en-US' : 'mn-MN'}>
                <ExcelExport ref={_export} />

                {grid}

            </LocalizationProvider>
            <GridPDFExport
                ref={(pdfExport) => (gridPDFExport = pdfExport)}
                landscape={false}
                paperSize="A4"
                margin="1cm"
                author="SainScore Team"
                scale={pdfExportScale}
                repeatHeaders={true}
                fileName={pdfExportFileName}
            >
                {grid}
            </GridPDFExport>
        </>

    );
});