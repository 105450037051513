import React from 'react'
import { useTranslation } from 'react-i18next'
import { FibaTextArea, Form } from '../../components'
import { Field } from '@progress/kendo-react-form'
import { Dialog } from "@progress/kendo-react-dialogs";

const InformationCorr = ({ modalState, closeModal, initdata }) => {

    const { t } = useTranslation()

    return (
        <>
            {modalState && (
                <Dialog
                    title={t("correction")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"auto"}
                >
                    <Form
                        read="lo010115"
                        afterRedirect={false}
                        onFinish={closeModal ?? null}
                        initialValues={{
                            id: initdata.id,
                        }}
                    >
                        <div className='mt-2'>
                            <Field
                                name={'description'}
                                component={FibaTextArea}
                                label={t('description')}
                                rows="3"
                                required={true}
                                placeholder={t('description')}
                                className={"min-w-[300px]"}
                            />
                        </div>
                    </Form>
                </Dialog>
            )}
        </>
    )
}

export default InformationCorr