import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FibaTextArea, Form, WebCamera } from '../../components'
import { Field } from '@progress/kendo-react-form'
import { Dialog } from "@progress/kendo-react-dialogs";

const InformationDecide = ({ modalState, closeModal, initdata }) => {

    const { t } = useTranslation()
    const [imgid, setSteImgid] = useState();
    const [step, setStep] = useState(1);

    const responseCallBack = (data) => {
        setSteImgid(data)
        if (data) {
            setStep(step + 1)
        }
    }

    return (
        <>
            {modalState && (
                <Dialog
                    title={t("decide")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"auto"}
                >
                    <Form
                        read="lo010113"
                        afterRedirect={false}
                        onFinish={closeModal ?? null}
                        userSendData={{ photo: imgid }}
                        initialValues={{
                            id: initdata.id,
                            userid: initdata.shiidwerlesen,
                        }}
                    >
                        <div className='mt-2'>
                            {step === 1 && <WebCamera setPhotoId={responseCallBack} />}
                            {step === 2 &&
                                <Field
                                    name={'description'}
                                    component={FibaTextArea}
                                    label={t('description')}
                                    rows="3"
                                    required={true}
                                    placeholder={t('description')}
                                    className={"min-w-[300px]"}
                                />
                            }
                        </div>
                    </Form>
                </Dialog>
            )}
        </>
    )
}

export default InformationDecide