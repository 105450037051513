import { GetCookie, LoadingSpin, send } from "../components";
import React, { useContext, useEffect, useState } from "react";
import { TOKEN_NAME } from "../constants/token.const";
const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [auth, setAuth] = useState({ user: null, loading: true, server_ip: '', txn_date: '' });

    function isAuth() {
        if (Object.keys(auth).length === 0) {
            return false;
        }
        return true;
    }

    const value = {
        auth,
        isAuth,
        setAuth
    };

    useEffect(() => {
        async function checkUser() {
            if (GetCookie(TOKEN_NAME) === undefined || GetCookie(TOKEN_NAME) === null || GetCookie(TOKEN_NAME) === "") {
                setAuth({});
                return;
            }
            const res = await send("lo000600", {});
            if (res.status !== 'error') {
                setAuth(res);
            } else {
                setAuth({})
            }

        }
        checkUser();
    }, []);

    return <AuthContext.Provider value={value}>{auth?.loading ? <LoadingSpin /> : children}</AuthContext.Provider>;
}

export default AuthContext;