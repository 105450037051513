import * as React from "react";
import { Error, Hint } from "@progress/kendo-react-labels";
import FibaButton from "../../button/FibaButton/FibaButton";

export const FibaTextArea = (fieldRenderProps) => {
  const { validationMessage, className, touched, label, id, hint, disabled, optional, required, fieldInfo, value, valid, json, visited, modified, onChange, ...others } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const validT = valid + "" ?? "";
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  let valueArea = value ?? "";
  const changeInputValue = () => {
    if (json) {
      try {
        valueArea = JSON.stringify(JSON.parse(valueArea), null, 4);
      } catch (e) {
        console.error(label + " талбар JSON format-тай байх ёстой.");
      }
      onChange({ value: valueArea });
    }
  };

  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700">
        {label}
        {required && <span className="k-required">*</span>}
        {json && (
          <span>
            <FibaButton
              className="fiba-i-json ml-2"
              title={"JSON format"}
              buttonType="icon"
              themeColor="primary"
              onClick={() => {
                changeInputValue();
              }}
            ></FibaButton>
          </span>
        )}
      </label>
      <div className={`mt-1`}>
        <textarea
          className="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
          value={valueArea.toString()}
          valid={validT}
          id={id}
          visited={visited ? visited + "" : ""}
          modified={modified ? modified + "" : ""}
          disabled={disabled}
          onChange={onChange}
          required={required}
          style={{ overflowY: "scroll", height: "100%", padding: "4px 8px" }}
          aria-describedby={`${hintId} ${errorId}`}
          {...others}
        ></textarea>
      </div>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {fieldInfo && <span className="k-field-info">*{fieldInfo}</span>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};
