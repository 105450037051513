import React from 'react'
import InformationDetail from './InformationDetail'
import { useNavigate, useParams } from 'react-router-dom'

const InformationDetailExternal = () => {

    const { id } = useParams();
    const navigate = useNavigate()

    const response = () => {
        navigate('/menu/problems');
    }

    return (
        <InformationDetail data={{id}} closeModal={response} modalState={true}/>
    )
}

export default InformationDetailExternal