import { GridColumn } from '@progress/kendo-react-grid'
import React from 'react'
import { ListScreen } from '../../components';

const Settings = () => {

    return (
        <div>
            <ListScreen
                read={'co000001'}
                deleteable={false}
                createable={false}
                dataItemKey={'code'}
            >
                <GridColumn field='id' />
                <GridColumn field='name' />
                <GridColumn field='code' />
            </ListScreen>
        </div>
    )
}

export default Settings