import React, { useState } from 'react'
import { FibaInput, Form } from '../../components';
import { useParams } from 'react-router-dom';
import { Field } from '@progress/kendo-react-form';
import { useTranslation } from 'react-i18next';

const SettingsChildCreate = () => {
    const { t } = useTranslation()
    const { code } = useParams()
    const [usersenddata] = useState({ parent_code: code });

    return (
        <div>
            <Form
                read={'co000003'}
                deleteable={false}
                dataItemKey={'code'}
                userSendData={usersenddata}
            >
                <div className={`grid md:grid-cols-3 sm:grid-cols-2 gap-4 mb-2 mt-8`}>
                    <Field
                        name={'name'}
                        component={FibaInput}
                        label={t('name')}
                        required={true}
                        placeholder={t('name')}
                    />
                    <Field
                        name={'name2'}
                        component={FibaInput}
                        label={t('name2')}
                        required={true}
                        placeholder={t('name2')}
                    />
                </div>
            </Form>
        </div>
    )
}

export default SettingsChildCreate