import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from "@progress/kendo-react-dialogs";
import { FibaDropdown, FibaInput, Form, send, useFibaMsgBox, useLoading } from '../../components';
import { Field } from '@progress/kendo-react-form';

const UserDetailEdit = ({ modalState, data, closeModal }) => {
    const { t } = useTranslation()
    const { error, success } = useFibaMsgBox();
    const { showLoading } = useLoading()

    const register = async (data) => {
        const res = await send("lo010117", data, showLoading);
        if (res.status === 'error') {
            error(res.message)
        } else {
            success(t('success'));
            closeModal()
        }
    }

    return (
        <div>
            {modalState && (
                <Dialog
                    title={t("more")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"80vh"}
                >
                    <Form
                        onSubmit={register}
                        showAction={false}
                        initialValues={data}
                    >
                        <div className="space-y-2">
                            <Field
                                name={'company'}
                                component={FibaDropdown}
                                label={t('company')}
                                required={true}
                                placeholder={t('company')}
                                dictCode={'DIC_009'}
                            />
                            <Field
                                name={'lastname'}
                                component={FibaInput}
                                label={t('lastname')}
                                required={true}
                                placeholder={t('lastname')}
                            />
                            <Field
                                name={'name'}
                                component={FibaInput}
                                label={t('name')}
                                required={true}
                                placeholder={t('name')}
                            />
                            <Field
                                name={'position'}
                                component={FibaInput}
                                label={t('position')}
                                required={true}
                                placeholder={t('position')}
                            />
                            <Field
                                name={'division'}
                                component={FibaDropdown}
                                label={t('division')}
                                dictCode={'DIC_007'}
                                required={true}
                            />
                            <Field
                                name={'phone'}
                                component={FibaInput}
                                label={t('phone')}
                            />
                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent bg-secondary-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
                                >
                                    {t('save')}
                                </button>
                            </div>
                        </div>
                    </Form>
                </Dialog>)}
        </div>
    )
}

export default UserDetailEdit