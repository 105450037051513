import React from 'react'
import { useTranslation } from 'react-i18next';
import { FibaDropdown, Form } from '../../components';
import { Field } from '@progress/kendo-react-form';
import { Dialog } from "@progress/kendo-react-dialogs";
import { ADMIN_ROLES } from '../../constants/data.cont';

const UserDetail = ({ modalState, data, closeModal }) => {
    const { t } = useTranslation();

    return (
        <>
            {modalState && (
                <Dialog
                    title={t("more")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"auto"}
                >
                    <Form
                        read="lo010105"
                        afterRedirect={false}
                        onFinish={closeModal ?? null}
                        initialValues={data}
                    >
                        <div className='grid sm:grid-cols-2 sm:gap-2 gap-1'>
                            <div className='sm:text-right font-bold'>
                                {t('company')}:
                            </div>
                            <div>{data.company}</div>
                            <div className='sm:text-right font-bold'>
                                {t('lastname')}:
                            </div>
                            <div>{data.lastname}</div>
                            <div className='sm:text-right font-bold'>
                                {t('name')}:
                            </div>
                            <div>{data.name}</div>
                            <div className='sm:text-right font-bold'>
                                {t('position')}:
                            </div>
                            <div>{data.position}</div>
                        </div>
                        <div className='mt-2'>

                            <Field
                                name={"role"}
                                component={FibaDropdown}
                                data={ADMIN_ROLES}
                                label={t("role")}
                                required={true}
                            />
                            <Field
                                name={"division"}
                                component={FibaDropdown}
                                dictCode={'DIC_007'}
                                label={t("division")}
                            />

                        </div>
                    </Form>
                </Dialog>
            )}
        </>
    )
}

export default UserDetail