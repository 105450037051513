import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FibaDropdown, FibaInput, FibaTextArea, Form, send, useFibaMsgBox, useLoading, WebCamera } from '../../components'
import { Field } from '@progress/kendo-react-form'
import FibaButton from '../../components/button/FibaButton/FibaButton'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { Button } from "@progress/kendo-react-buttons";

const Threat = () => {
    const { t } = useTranslation()
    const [step, setStep] = useState(1);
    const [imgid, setSteImgid] = useState();
    const [address, setAddress] = useState();
    const { error, success, confirm } = useFibaMsgBox();
    const { showLoading } = useLoading()
    const navigate = useNavigate()

    const responseCallBack = (data) => {
        setSteImgid(data)
        if (data) {
            setStep(step + 1)
        }
    }

    const submitData = async (data) => {
        const isconfirm = await confirm(t('confirmsave'));
        if (isconfirm) {
            const res = await send("lo010106", { ...data, photo: imgid }, showLoading);
            if (res.status === 'error') {
                error(res.message)
            } else {
                success(t('success'));
                navigate('/');
            }
        }
    }

    return (
        <div>
            <Form
                create={'lo010106'}
                showAction={false}
                onSubmit={submitData}
            >
                {step === 1 && <WebCamera setPhotoId={responseCallBack} />}
                <div className={`grid md:grid-cols-3 sm:grid-cols-2 gap-4 mb-2 mt-8 ${step === 2 ? '' : 'sr-only'}`}>
                    <Field
                        name={'division'}
                        component={FibaDropdown}
                        label={t('threatdivision')}
                        required={true}
                        dictCode={'DIC_007'}
                    />
                    <Field
                        name={'impactthread'}
                        component={FibaDropdown}
                        label={t('impactthread')}
                        required={true}
                        dictCode={'DIC_001'}
                    />
                    <Field
                        name={'hazardclassification'}
                        component={FibaDropdown}
                        label={t('hazardclassification')}
                        required={true}
                        dictCode={'DIC_004'}
                        filterable={true}
                    />
                    <Field
                        name={'levelrisk'}
                        component={FibaDropdown}
                        label={t('levelrisk')}
                        required={true}
                        placeholder={t('levelrisk')}
                        dictCode={'DIC_003'}
                    />
                    <Field
                        name={'address'}
                        component={FibaDropdown}
                        label={t('address')}
                        required={true}
                        dictCode={'DIC_008'}
                        onChange={(evnt) => {
                            console.log(evnt);
                            setAddress(evnt.value)
                        }}
                    />
                    {address === '00' && <Field
                        name={'address1'}
                        component={FibaInput}
                        label={t('address')}
                        required={true}
                    />}
                    <div className="col-span-full">
                        <Field
                            name={'promptaction'}
                            component={FibaTextArea}
                            label={t('promptaction')}
                            rows="3"
                            required={true}
                            placeholder={t('promptaction')}
                        />
                    </div>
                    <div className="col-span-full">
                        <Field
                            name={'definitionrisk'}
                            component={FibaTextArea}
                            label={t('definitionrisk')}
                            rows="3"
                            required={true}
                            placeholder={t('definitionrisk')}
                        />
                    </div>
                </div>
                {imgid && <div className='flex justify-center mt-20 space-x-10'>
                    {step > 1 && <Button themeColor='secondary'
                        fillMode="outline"
                        onClick={() => setStep(step - 1)}
                        icon={"arrow-left"}
                    >
                        {t('preview')}
                    </Button>}
                    {step > 1 ? <FibaButton themeColor='secondary' type='submit'>
                        {t('save')}
                    </FibaButton> :
                        <Button
                            fillMode="outline"
                            themeColor={'primary'}
                            onClick={() => setStep(step + 1)}
                        >
                            <span className='flex'>
                                {t('next')}
                                <ArrowRightIcon className='w-5 h-5' />
                            </span>
                        </Button>}
                </div>}
            </Form>
        </div>
    )
}

export default Threat