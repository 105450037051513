import React from 'react'
import Webcam from 'react-webcam';
import { ExclamationTriangleIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useFibaMsgBox, useLoading } from '../features';
import axios from 'axios';
import { GetCookie, SERVER_HOST } from '../service';
import { TOKEN_NAME } from '../../constants/token.const';

const videoConstraints = {
    width: 540,
    facingMode: "environment"
};

export const WebCamera = ({ setPhotoId }) => {
    const webcamRef = React.useRef(null);
    const { t } = useTranslation()
    const { showLoading } = useLoading()
    const [cameraGranted, setCameraGranted] = React.useState(null); // To track permission status
    const [errorMessage, setErrorMessage] = React.useState('');     // To handle any errors
    const { error } = useFibaMsgBox()

    const onChangePicture = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            // setSelectedFile(undefined)
            return;
        }
        // setImageSrc(e.target.files[0])
        uploadPhoto(e.target.files[0])
    }

    const capture = () => {
        const image = webcamRef.current.getScreenshot();
        console.log(image);
        // setImageSrc(image);
        uploadPhoto(image)
    };

    const uploadPhoto = async (data) => {

        const formData = new FormData();
        formData.append("image", data);
        formData.append("type", 'GPA');

        showLoading(true)
        axios.post(SERVER_HOST + "/api/v1/upload/image", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + GetCookie(TOKEN_NAME),
            },
            transformRequest: formData => formData,
        }).then((res) => {
            if (res.data.response_code === "RC000000") {
                // setId(res.data.response)
                // onChange({ value: res.data.response });
                // setSelectedFile(data)
                if (setPhotoId) {
                    setPhotoId(res.data.response)
                }
            } else {
                error(t('cannot_upload'))
                console.error(res.data);
            }
        }).catch((err) => {
            error(t('cannot_upload'))
            console.log(err);
        }).finally(() => {
            showLoading(false);
        })
    }

    // const handleClick = React.useCallback(() => {
    //     setFacingMode(
    //         prevState =>
    //             prevState === FACING_MODE_USER
    //                 ? FACING_MODE_ENVIRONMENT
    //                 : FACING_MODE_USER
    //     );
    // }, []);

    const requestCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setCameraGranted(true); // Permission granted
            setErrorMessage('');
            // Permission granted, you can use the camera now
            console.log("Camera access granted", stream);
        } catch (error) {
            setCameraGranted(false); // Permission denied or error occurred
            setErrorMessage("You need to allow camera access to continue.");
            console.error("Error accessing camera:", error);
        }
    };

    const onUserMedia = (e) => {
        console.log(e);
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (webcamRef.current) {
                if (webcamRef.current.state && webcamRef.current.state.hasUserMedia) {
                    setCameraGranted(true);
                } else {
                    setCameraGranted(false);
                }
            }
        }, 1000);

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {cameraGranted === null && (
                <div>
                    <p>Камерт хандаж зураг авахыг зөвшөөрнө үү.</p>
                    <button onClick={requestCameraPermission}>Камерт хандах зөвшөөрөл өгөх</button>
                </div>
            )}
            <Webcam
                audio={false}
                screenshotFormat="image/webp"
                ref={webcamRef}
                onUserMedia={onUserMedia}
                videoConstraints={videoConstraints}
            />
            {/* If permission is granted */}
            {cameraGranted === true &&
                <>
                    {/* <button onClick={handleClick}>Switch camera</button> */}

                    <div className='mx-auto relative z-10 space-x-2'>
                        <div className='flex -mt-16 justify-between px-3 py-1 bg-black/70 rounded-full'>
                            <div className='mt-2 w-10 h-10 flex justify-center items-center'></div>
                            <div className='group mx-auto rounded-full border-4 w-14 h-14 flex justify-center items-center border-white/90 hover:border-white'>
                                <button
                                    type='button'
                                    className='rounded-full group-hover:bg-white w-11 h-11 bg-white/80'
                                    onClick={capture}
                                ></button>
                            </div>
                            <div className='mt-2 rounded-full hover:bg-white bg-white/90 w-10 h-10 flex justify-center items-center'>
                                <input
                                    className='sr-only'
                                    accept="image/*"
                                    id={'fiba-file'}
                                    type={'file'}
                                    valid={'false'}
                                    touched={'false'}
                                    modified={'false'}
                                    visited={'false'}
                                    onChange={onChangePicture}
                                    name={'file_image'}
                                />
                                <label htmlFor="fiba-file">

                                    <PhotoIcon className='w-6 h-6  text-black' />
                                </label>
                            </div>

                        </div>

                    </div>
                </>
            }

            {/* If permission is denied or there was an error */}
            {cameraGranted === false && (
                <div className='text-center bg-red-400 rounded text-white flex items-center font-semibold text-base p-2 leading-tight'>
                    <div className='m-3'>
                        <ExclamationTriangleIcon className='w-10 h-10' />
                    </div>
                    <p>Камерт хандах эрх өгөгдөөгүй байна. Үргэлжлүүлэхийн тулд та камерын хандалтыг зөвшөөрөх шаардлагатай. {errorMessage}</p>
                </div>
            )}


        </>
    )
}
