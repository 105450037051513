export const ADMIN_ROLES = [
    {
        value: 1,
        name: 'admin'
    },
    {
        value: 2,
        name: 'moaz'
    },
    {
        value: 3,
        name: 'udirdlaga'
    },
    {
        value: 4,
        name: 'employee'
    },
];

export const INFORMATIONTYPE = [
    {
        value: '1',
        name: 'threat'
    },
    {
        value: '2',
        name: 'violation'
    }
]

export const INFORMATIONSTATUS = [
    {
        value: '1',
        name: 'new'
    },
    {
        value: '2',
        name: 'allocated'
    },
    {
        value: '3',
        name: 'decided'
    },
    {
        value: '4',
        name: 'checkconfirm'
    },
    {
        value: '5',
        name: 'returned'
    },
]